<script>
import TerminalLayoutMixin from "@/mixins/terminal-layout.mixin"

export default {
  name: "LocationCollapsible",
  emits: ["select"],
  props: {
    location: Object,
    isOpen: {
      default: true,
      type: Boolean,
    },
    onCollapse: {
      default: () => {},
      type: Function,
    },
  },

  mixins: [TerminalLayoutMixin],

  data() {
    return {
      open: this.isOpen,
      height: this.isOpen ? "auto" : "0px",
      closeOnNextTick: false,
      transitioning: false,
    }
  },

  updated() {
    this.$nextTick(() => {
      window.setTimeout(() => {
        if (this.closeOnNextTick) {
          this.height = "0px"
          this.closeOnNextTick = false
        }
      })
    })
  },

  watch: {
    open(newVal, oldVal) {
      this.transitioning = true
      this.height = this.$refs.inner.scrollHeight + "px"

      if (oldVal === true) {
        this.closeOnNextTick = true
      }
    },
    isOpen(newVal) {
      this.open = newVal
    },
  },

  methods: {
    handleClick() {
      if (this.transitioning) return
      this.onCollapse(!this.isOpen)
      this.open = !this.open
    },
    handleEnd() {
      if (this.height !== "0px") {
        this.height = "auto"
      }
      this.transitioning = false
    },
  },
}
</script>

<template>
  <div class="flex-1 w-full">
    <button
      type="button"
      class="flex items-center justify-between w-full text-base font-bold py-1.5 focus:outline-none pl-3"
      :class="{
        'collapsible__trigger--open': !transitioning && open,
        'collapsible__trigger--closed': !transitioning && !open,
        'collapsible__trigger--transitioning': transitioning,
      }"
      @click.prevent="handleClick"
    >
      <span class="inline-block underline underline-dashed">{{ location.attributes.name }}</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        viewBox="0 0 16 16"
        :class="{
          'transform rotate-180': !open,
        }"
      >
        <path
          d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
        />
      </svg>
    </button>

    <div
      class="overflow-hidden"
      :class="{
        'collapsible__content--open': !transitioning && open,
        'collapsible__content--closed': !transitioning && !open,
        'collapsible__content--transitioning': transitioning,
      }"
      :style="{
        height,
        transitionProperty: 'height',
        transitionDuration: '400ms',
        transitionTimingFunction: 'ease',
      }"
      @transitionend="handleEnd"
    >
      <div
        ref="inner"
        class="h-auto flex flex-wrap gap-y-1 pt-2.5"
        :class="`${isMobileOrderingButtons ? 'flex-row gap-y-1 gap-x-2' : 'flex-col'}`"
      >
        <template v-if="location.areas.length">
          <router-link
            v-for="(area, index) in location.areas"
            :key="index"
            :to="{ name: 'mobilepos.tables', params: { area_id: area.id } }"
            class="p-4 flex list-item"
          >
            <div class="text font-bold list-">{{ area.attributes.name }}</div>
          </router-link>
        </template>

        <span v-else> No areas found </span>
      </div>
    </div>
  </div>
</template>
